import { APICore } from "../../helpers/api/apiCore";
import {
  setCreateStationModal, setStationError,
  setStationLoading, setStations
} from "./actions";

export interface Photo {
  photoUrl: string;
  _id?: string;
}

export interface StationData {
  id?: string;
  name: string;
  ln: string | null;
  lt: string | null;
  photoStation? :File;
  address?: string | null;
  mapLink?: string | null;
  mapLinkReturn?: string | null;
  stationInformation?: string | null;
  stationInformationReturn?: string | null;
  photoStepOne?: File | null;
  photoStepTwo?: File | null;
  photoStepThree?: File | null;
  photoStepFour?: File | null;
  photoStepFive?: File | null;
  photoStepSix?: File | null;
  photoStepSeven?: File | null;
  photoStepEight?: File | null;
  photoStepNine?: File | null;
  photoStepTen?: File | null;
  photoStepEleven?: File | null;
  photoStepTwelve?: File | null;
  descriptionStepOne?: string | null;
  descriptionStepTwo?: string | null;
  descriptionStepThree?: string | null;
  descriptionStepFour?: string | null;
  descriptionStepFive?: string | null;
  descriptionStepSix?: string | null
  descriptionStepOneReturn?: string | null;
  descriptionStepTwoReturn?: string | null;
  descriptionStepThreeReturn?: string | null;
  descriptionStepFourReturn?: string | null;
  descriptionStepFiveReturn?: string | null;
  descriptionStepSixReturn?: string | null;
}

/**
 * Load cities thunk.
 *
 * @param param0
 * @returns
 */
export const loadStationsThunk = () => {
  return async (dispatch: any, getState: any, api: APICore) => {
    try {
      dispatch(setStationLoading(true));

      const resp = await api.get("/stations");

      if (resp.data?.status === "OK") {
        dispatch(setStations(resp.data.result));
      }
    } catch (error: any) {
      let err = error;
      if (error.response) {
        err = error.response.data.error;
      }

      dispatch(setStationError(err));
    }
  };
};

/**
 * Create city thunk.
 *
 * @param param0
 * @returns
 */
export const createStationThunk = (data, photo) => {
  console.log("ma requete recu", data)
  console.log("photo",photo)
  const formData = new FormData();


  formData.append("name", data.name);
      formData.append("ln", data.ln);
      formData.append("lt", data.lt.toString());
      formData.append("photoUrl", photo);
      formData.append("address", data.address);
      formData.append("mapLink", data.mapLink);
      formData.append("mapLinkReturn", data.mapLinkReturn);
      formData.append("stationInformation", data.stationInformation);
      formData.append("descriptionStepOne",data.step[0].description);
      formData.append("photoStepOne", data.step[0].photo);
      formData.append("descriptionStepTwo", data.step[1].description);
      formData.append("photoStepTwo", data.step[1].photo);
      formData.append("descriptionStepThree", data.step[2].description);
      formData.append("photoStepThree", data.step[2].photo);
      formData.append("descriptionStepFour", data.step[3].description);
      formData.append("photoStepFour", data.step[3].photo);
      formData.append("descriptionStepFive", data.step[4].description);
      formData.append("photoStepFive", data.step[4].photo);
      formData.append("descriptionStepSix", data.step[5].description);
      formData.append("photoStepSix", data.step[5].photo);
      formData.append("stationInformationReturn", data.stationInformationReturn);
      formData.append("descriptionStepOneReturn", data.stepReturn[0].description);
      formData.append("photoStepOneReturn", data.stepReturn[0].photo);
      formData.append("descriptionStepTwoReturn", data.stepReturn[1].description);
      formData.append("photoStepTwoReturn", data.stepReturn[1].photo);
      formData.append("descriptionStepThreeReturn", data.stepReturn[2].description);
      formData.append("photoStepThreeReturn", data.stepReturn[2].photo);
      formData.append("descriptionStepFourReturn", data.stepReturn[3].description);
      formData.append("photoStepFourReturn", data.stepReturn[3].photo);
      formData.append("descriptionStepFiveReturn", data.stepReturn[4].description);
      formData.append("photoStepFiveReturn", data.stepReturn[4].photo);
      formData.append("descriptionStepSixReturn", data.stepReturn[5].description);
      formData.append("photoStepSixReturn", data.stepReturn[5].photo);

  return async (dispatch: any, getState: any, api: APICore) => {
    try {
      const url = data.id ? `/admin/stations/${data.id}` : "/admin/stations";
      const resp = data.id ? await api.update(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }) : await api.create(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      console.log("response", resp)

      if (resp.data) {
        dispatch(loadStationsThunk());
        dispatch(setCreateStationModal(false));
      }
    } catch (error: any) {
      console.log({ error });

      let err = error;
      if (error.response) {
        err = error.response.data.error;
      }

      dispatch(setStationError(err));
    }
  };
};



// export const createOrUpdateServiceOptions = ({}: ServiceOptionsData) => {};
